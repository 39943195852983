import { HeadCard } from "../../structure/card/HeadCard"
import Select from 'react-select';
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";


import './EditarImpuestoPage.css';
import { editarImpuestosService, getImpuestos, getNits } from "../../../services/impuestos/impuestos";
import { getCiudades } from "../../../services/ciudades/ciudadService";
import { DatesModal } from "./dates-modal/DatesModal";
import Swal from "sweetalert2";

export const EditarImpuestoPage = () => {

    const { register, handleSubmit, formState: { errors }, control, setError, clearErrors } = useForm();


    const [selectedNits, setSelectedNits] = useState([]);
    const [selectedRegimes, setSelectedRegimes] = useState([]);
    const [showDatesModal, setShowDatesModal] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [taxes, setTaxes] = useState([]);
    const [cities, setCities] = useState([]);
    const [nits, setNits] = useState([]);
    const [dataErrors, setDataErrors] = useState([]);


    const regimens = [{
        id: 0,
        label: 'Natural'
    },
    {
        id: 1,
        label: 'Juridico'
    },
    {
        id: 2,
        label: 'Contribuyente'
    }
    ];



    useEffect(() => {

        getTaxes();
        getCities();
        getNitsData();
        setIsLoading(false);
    }, [])


    const getTaxes = () => {

        getImpuestos().then((taxesData) => {

            setTaxes(taxesData.map((tax) => {
                tax.label = tax.impuesto;
                return tax;
            }));
        }).catch((e) => {
            console.log("Error TAXES ", e);
            //Implemntar variable de error
            setIsLoading(false);
            setDataErrors([...dataErrors, e]);
        });

    }

    const getCities = () => {
        getCiudades().then((citiesData) => {

            setCities(citiesData.map((city) => {
                city.label = city.ciudad;
                return city;
            }));
        }).catch((e) => {
            console.log("Error CITIES ", e);
            //Implemntar variable de error
            setIsLoading(false);
            setDataErrors([...dataErrors, e]);
        })
    }

    const getNitsData = () => {
        getNits().then((nitsData) => {

            setNits(nitsData.map((nit) => {
                nit.label = nit.nit;
                return nit;
            }));
        }).catch((e) => {
            //Implemntar variable de error
            setIsLoading(false);
            setDataErrors([...dataErrors, e]);
        })
    }

    const handleDatesModal = () => {
        setShowDatesModal(!showDatesModal);
    }

    const onSubmit = (data) => {

        const dataValidation = validateFields(data);

        if (dataValidation) {
            editarImpuestosService(data).then((msg) => {
                Swal.fire('Impuestos actualizados', msg, 'success');

            }).catch((e) => {
                Swal.fire('Error', e.msg, 'error');
            })
        }
    }

    const validateFields = (data) => {
        const dateFields = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

        // Verifica que al menos uno de los campos opcionales esté diligenciado
        const someDateFilled = dateFields.some(

            (campo) => (data[campo] !== undefined) && (data[campo]?.trim() !== '')
        );

        if (!someDateFilled) {
            setError('fecha', {
                type: 'manual',
                message: 'Debes seleccionar una fecha',
            });
        }
        return someDateFilled;
    };

    const cleanDateError = () => clearErrors('fecha');


    return (
        <div className="content-wrapper">
            <HeadCard titulo="Editar Impuestos" />
            {
                !isLoading && dataErrors.length === 0 && (
                    <section className="content  mx-2">
                        <div className="card">

                            <div className="card-body">

                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">

                                        <div className="col-lg-4 mt-2">
                                            <h6>
                                                Nombre del impuesto
                                            </h6>



                                            <Controller
                                                control={control}
                                                name="impuesto"
                                                rules={{ required: true }}
                                                render={({ onChange, value, name, ref, field }) => (

                                                    <Select
                                                        inputRef={ref}
                                                        options={taxes}
                                                        placeholder="Nombre del impuesto"
                                                        onChange={val => {
                                                            field.onChange(val.id)
                                                        }}
                                                        getOptionValue={impuesto => impuesto.label}
                                                    />
                                                )}
                                            />
                                            {errors.impuesto && <p className="text-danger mt-2">Debes seleccionar un impuesto</p>}

                                        </div>


                                        <div className="col-lg-3 mt-2">
                                            <h6>
                                                Ciudad
                                            </h6>



                                            <Controller
                                                control={control}
                                                name="ciudad"
                                                rules={{ required: true }}
                                                render={({ ref, field }) => (

                                                    <Select
                                                        inputRef={ref}
                                                        options={cities}
                                                        placeholder="Ciudad"
                                                        onChange={val => {
                                                            field.onChange(val.id)
                                                        }}
                                                        getOptionValue={ciudad => ciudad.label}
                                                    />
                                                )}
                                            />
                                            {errors.ciudad && <p className="text-danger mt-2" >Debes seleccionar una ciudad</p>}

                                        </div>

                                        <div className="col-lg-2 mt-2">
                                            <h6>
                                                NIT
                                            </h6>


                                            <Controller
                                                control={control}
                                                name="nits"
                                                rules={{ required: true }}
                                                render={({ ref, field }) => (

                                                    <MultiSelect
                                                        inputRef={ref}
                                                        value={selectedNits}
                                                        onChange={(e) => {
                                                            setSelectedNits(e.value);
                                                            console.log(e);
                                                            field.onChange(e.value.map((nit) => nit.id));
                                                        }}
                                                        options={nits}
                                                        optionLabel="label"
                                                        placeholder="NIT"
                                                        maxSelectedLabels={2}
                                                        className="w-full md:w-20rem"
                                                        display="chip"

                                                    />
                                                )}
                                            />
                                            {errors.nits && <p className="text-danger mt-2">Debes seleccionar un NIT</p>}

                                        </div>

                                        <div className="col-lg-3 mt-2">
                                            <h6>
                                                REGIMEN
                                            </h6>

                                            <Controller
                                                control={control}
                                                name="regimen"
                                                rules={{ required: true }}
                                                render={({ ref, field }) => (
                                                    <MultiSelect
                                                        inputRef={ref}
                                                        value={selectedRegimes}
                                                        onChange={(e) => {
                                                            console.log(e);
                                                            setSelectedRegimes(e.value);
                                                            field.onChange(e.value.map((regime) => regime.label));
                                                        }}
                                                        options={regimens}
                                                        optionLabel="label"
                                                        placeholder="Regimen"
                                                        maxSelectedLabels={2}
                                                        className="w-full md:w-20rem"
                                                        display="chip"
                                                    />
                                                )}
                                            />
                                            {errors.regimen && <p className="text-danger mt-2">Debes seleccionar un regimen</p>}

                                        </div>

                                        <div className="col-lg-3 mt-2">
                                            <h6>Fecha</h6>
                                            <div className="input-group mt-2" onClick={handleDatesModal}>

                                                <input type="text" className="form-control" placeholder="dd/mm/aaaa" readOnly />
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fas fa-calendar" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            {errors.fecha && <p className="text-danger mt-2">Debes seleccionar una fecha</p>}
                                        </div>
                                    </div>




                                    <button type="submit" className=" col-lg-4 mt-4 text-white btn btncrear">Actualizar impuestos</button>
                                </form>



                            </div>

                        </div>
                    </section>
                )
            }

            {dataErrors && (
                <div>
                    {dataErrors.map((error) => <div> <p>{error}</p></div>)}
                </div>
            )}


            <DatesModal showModal={showDatesModal} setShowModal={setShowDatesModal} register={register} callback={cleanDateError} />
        </div>
    )
}
