import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { customStyles } from '../../../../helpers/modal_styles';
import './UsuarioEditModal.css'
import { useDispatch } from "react-redux";
import { onEditarUsuario } from '../../../../services/usuarios/usuarioService';
import { updateStateForId } from '../../../../helpers/updateState';




// const verificado = [
//     { value: "NoValidado", label: "No validado" },
//     { value: "Espera", label: "Espera" },
//     { value: "Validado", label: "Validado" }
// ];

const estado = [
    { value: "0", label: "Desactivado" },
    { value: "1", label: "Activado" },
];

let ciudad = [];
let rol = [];




export const UsuarioEditModal = ({ setmodalEditar, modalEditar, editar, state, setState, ciudades, roles }) => {
    const dispatch = useDispatch();
    Modal.setAppElement('#root');
;
console.log(editar);
console.log(modalEditar);

    const [formValues, setFormValues] = useState({
        ciudad: 107,
        email: "",
        estado: "1",
        id: 1,
        labelCiudad: {},
        labelRol: "",
        rol_id: 3
    });

    useEffect(() => {
        ;
        console.log(editar);
        const initialState = {
            id: editar.id === undefined ? 1:editar.id,
            email: editar.email === undefined ? "":editar.email ,
            ciudad: editar.id_ciudad === undefined ? 127 : editar.id_ciudad,
            estado: editar.estado === undefined ?"1":editar.estado,
            rol_id: editar.id_rol === undefined ?3:editar.id_rol,
            labelCiudad:editar.Ciudade === undefined ?{}:editar.Ciudade.ciudad,
            labelRol:editar.Role === undefined ?"":editar.Role.rol,
        }
        ;
        setFormValues(initialState);
        ;
    }, [editar])

    useEffect(() => {
        ciudad = [];
        ciudades.forEach(element => {
            ciudad.push({"value":element.id, "label":element.ciudad})
        });
    }, [ciudades])

    useEffect(() => {
        rol=[];
        roles.forEach(rolModel => {
            rol.push({"value":rolModel.id, "label":rolModel.rol})
        });
    }, [roles])




    //FUNCTION========================
    const closeModal = () => {
        setmodalEditar(false);
    }

    const editarUsuario = async(e) => {
        e.preventDefault();

        const resp = await dispatch(onEditarUsuario(
            formValues.id, 
            formValues.email,
            formValues.ciudad,
            formValues.estado,
            formValues.rol_id   
            ));

            const newArray = updateStateForId(state, resp);
            setState([...newArray]);
            closeModal();

    }

    //FIN FUNCTION========================
;
console.log("FORMVALUES FAB;;;;;;;", formValues);
console.log("FORMVALUES FAB rol_id;;;;;;;", formValues.rol_id);
console.log("FORMVALUES FABlabelRol;;;;;;;", formValues.labelRol);
    return (
        <Modal
            isOpen={modalEditar}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className='popup'>
                

           <center><h3>Editar Usuario</h3></center>
            <br />
            <form onSubmit={editarUsuario}>

                <div className="form-row">
                    <div className="form-grupin col-md-12">
                        <input
                            placeholder="email"
                            type="text"
                            name="email"
                            className="form-control"
                            value={formValues.email}
                            onChange={(e) =>
                                setFormValues({ ...formValues, email: e.target.value })
                            }
                        />
                    </div>
                </div>

                {/* <div className="form-row">
                    <div className=" form-grupin col-md-12">
                    <Select 
                        options={ciudad} 
                        placeholder="Ciudad"  
                        value={{
                            value: formValues.ciudad,
                            label: formValues.labelCiudad
                          }}
                        onChange={(e) => {
                            ;
                            console.log(e);
                            ;
                            setFormValues({ ...formValues, ciudad: Number(e.value), labelCiudad:e.label  })} }
                        />
                    </div>
                </div> */}

                {/* <div className="form-row">
                <div className=" form-grupin col-md-12">
                        <Select options={rol} 
                        placeholder="Rol"  
                        value={{
                            value: formValues.rol_id,
                            label: formValues.labelRol
                          }}
                        onChange={(e) => setFormValues({ ...formValues, rol_id: e.value, labelRol:e.label })} 
                        />
                    </div>
                </div> */}
                
                <div className="form-row">
                <div className=" form-grupin col-md-12">
                        <Select
                        options={estado}
                        value={{
                            value: formValues.estado,
                            label: formValues.estado===1?"Activo":"Desactivado"
                          }}
                        placeholder="Estado"  
                        onChange={(e) => setFormValues({ ...formValues, estado: e.value })} 
                        />
                    </div>
                </div>

                <button className="btn btncrear">Editar</button>
            </form>
            </div>

        </Modal>
    )
}
