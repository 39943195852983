import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { customStyles } from '../../../../helpers/modal_styles';
import './DetalleImpuestoEditModal.css'
import { useDispatch } from "react-redux";
import { updateStateForId } from '../../../../helpers/updateState';
import Moment from "moment";
import { onEditarDetalleImpuesto } from '../../../../services/detalleimpuestos/detalleImpuestoService';


const estado = [
    { value: "0", label: "Desactivado" },
    { value: "1", label: "Activado" },
];



export const DetalleImpuestoEditModal = ({ setmodalEditar, modalEditar, editar, state, setState }) => {
    const dispatch = useDispatch();
    Modal.setAppElement('#root');


    const [formValues, setFormValues] = useState({});
console.log(editar);
;
    useEffect(() => {
        const initialState = {
            id: editar.id,
            enero: editar.enero,
            febrero: editar.febrero,
            marzo: editar.marzo,
            abril: editar.abril,
            mayo: editar.mayo,
            junio: editar.junio,
            julio: editar.julio,
            agosto: editar.agosto,
            septiembre: editar.septiembre,
            octubre: editar.octubre,
            noviembre: editar.noviembre,
            diciembre: editar.diciembre,
            estado: editar.estado,
        }
        ;
        setFormValues(initialState);
        ;
    }, [editar])




    //FUNCTION========================
    const closeModal = () => {
        setmodalEditar(false);
    }

    const editarUsuario = async(e) => {
        e.preventDefault();
;
        const resp = await dispatch(onEditarDetalleImpuesto(
            formValues.id, 
            Moment(formValues.enero).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.febrero).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.marzo).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.abril).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.mayo).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.junio).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.julio).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.agosto).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.septiembre).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.octubre).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.noviembre).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            Moment(formValues.diciembre).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
            formValues.estado,
            ));
;
            const newArray = updateStateForId(state, resp);
            setState([...newArray]);
            closeModal();

    }

    //FIN FUNCTION========================

    console.log("FORMVALUES;;;;;;",formValues);
    console.log("TIPO_::::::::::",typeof(formValues.enero));
;
    return (
        <Modal
            isOpen={modalEditar}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className='popup'>
                

           <center><h3>Editar Impuesto</h3></center>
            <br />
            <form onSubmit={editarUsuario}>

  
                <div className="form-row">
                <div className=" form-grupin col-md-12">
                        <Select
                        options={estado}
                        value={{
                            value: formValues.estado,
                            label: formValues.estado===1?"Activo":"Desactivado"
                          }}
                        placeholder="Estado"  
                        onChange={(e) => setFormValues({ ...formValues, estado: e.value })} 
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-grupin col-md-4">
                    <h6>Enero</h6>
                        <input
                            placeholder="Enero"
                            type="date"
                            name="enero"
                            className="form-control"
                            value={Moment(formValues.enero).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, enero: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Febrero</h6>
                        <input
                            placeholder="Febrero"
                            type="date"
                            name="febrero"
                            className="form-control"
                            value={Moment(formValues.febrero).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, febrero: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Marzo</h6>
                        <input
                            placeholder="Marzo"
                            type="date"
                            name="marzo"
                            className="form-control"
                            value={Moment(formValues.marzo).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, marzo: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-grupin col-md-4">
                    <h6>Abril</h6>
                        <input
                            placeholder="Abril"
                            type="date"
                            name="abril"
                            className="form-control"
                            value={Moment(formValues.abril).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, abril: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Mayo</h6>
                        <input
                            placeholder="Mayo"
                            type="date"
                            name="mayo"
                            className="form-control"
                            value={Moment(formValues.mayo).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, mayo: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Junio</h6>
                        <input
                            placeholder="Junio"
                            type="date"
                            name="junio"
                            className="form-control"
                            value={Moment(formValues.junio).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, junio: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-grupin col-md-4">
                    <h6>Julio</h6>
                        <input
                            placeholder="Julio"
                            type="date"
                            name="julio"
                            className="form-control"
                            value={Moment(formValues.julio).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, julio: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Agosto</h6>
                        <input
                            placeholder="Agosto"
                            type="date"
                            name="agosto"
                            className="form-control"
                            value={Moment(formValues.agosto).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, agosto: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Septiembre</h6>
                        <input
                            placeholder="Septiembre"
                            type="date"
                            name="septiembre"
                            className="form-control"
                            value={Moment(formValues.septiembre).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, septiembre: e.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-grupin col-md-4">
                    <h6>Octubre</h6>
                        <input
                            placeholder="Octubre"
                            type="date"
                            name="octubre"
                            className="form-control"
                            value={Moment(formValues.octubre).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, octubre: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Noviembre</h6>
                        <input
                            placeholder="Noviembre"
                            type="date"
                            name="noviembre"
                            className="form-control"
                            value={Moment(formValues.noviembre).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, noviembre: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                    <h6>Diciembre</h6>
                        <input
                            placeholder="Diciembre"
                            type="date"
                            name="diciembre"
                            className="form-control"
                            value={Moment(formValues.diciembre).format("YYYY-MM-DD")}
                            onChange={(e) =>
                                setFormValues({ ...formValues, diciembre: e.target.value })
                            }
                        />
                    </div>
                </div>

                <button className="btn btncrear">Editar</button>
            </form>
            </div>

        </Modal>
    )
}
