export const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: '60%',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90vh',
      overflowy: "auto",
      borderRadius:"40px"
    },
  };