import React, { useState } from 'react'
import { CiudadCrearModal } from '../../dashboard/ciudades/crear-model/CiudadCrearModal';
import { DetalleImpuestoCrearModal } from '../../dashboard/detalleimpuestos/crear-model/DetalleImpuestoCrearModal';
import { NameImpuestoCrearModal } from '../../dashboard/nameimpuestos/crear-model/NameImpuestoCrearModal';
import { UsuarioCrearModal } from '../../dashboard/users/crear-modal/UsuarioCrearModal';
import { DatesModal } from '../../dashboard/editar-impuestos/dates-modal/DatesModal';

export const FooterCard = ({ state, setState, texto = "", modalKey = "", ciudades, nit, impuestosNombre, dates, setDates }) => {

    const [modalCrear, setmodalCrear] = useState(false);

    const onActivarModal = () => {
        setmodalCrear(true)
    }
    return (
        <>
            <div className="card-footer row">
                <div className="col-md-3">
                    <button type="button" className="btncrear btn btn-block btn-lg" onClick={onActivarModal}>{texto}</button>
                </div>
            </div>

            {modalKey === "usuario" ?
                (
                    <UsuarioCrearModal
                        setmodalCrear={setmodalCrear}
                        modalCrear={modalCrear}
                        state={state}
                        setState={setState}
                    />
                ) : modalKey === "ciudad" ? (
                    <CiudadCrearModal
                        setmodalCrear={setmodalCrear}
                        modalCrear={modalCrear}
                        state={state}
                        setState={setState}
                    />
                ) : modalKey === "detalleimpuesto" ? (
                    <DetalleImpuestoCrearModal
                        setmodalCrear={setmodalCrear}
                        modalCrear={modalCrear}
                        state={state}
                        setState={setState}
                        ciudades={ciudades}
                        nit={nit}
                        impuestosNombre={impuestosNombre}
                    />
                ) : modalKey === "nameImpuesto" ? (
                    <NameImpuestoCrearModal
                        setmodalCrear={setmodalCrear}
                        modalCrear={modalCrear}
                        state={state}
                        setState={setState}
                    />
                ) : modalKey === "dates" ? (
                    <DatesModal
                        setmodalCrear={setmodalCrear}
                        modalCrear={modalCrear}
                        dates={dates}
                        setDates={setDates}
                    />
                ) :
                    (
                        <p>error</p>
                    )}
            {/* <UsuarioCrearModal setmodalCrear={setmodalCrear} modalCrear={modalCrear}
        state={state} setState={setState} /> */}
        </>
    )
}
