import React from 'react'
import { UserCharts } from '../../charts/UserCharts'
import { HeadCard } from '../../structure/card/HeadCard'
import { MembresiaCharts } from '../../charts/MembresiaCharts';


export const HomePage = () => {



    ;
    return (
        <div className="content-wrapper">
            <HeadCard titulo="INICIO" />
            <section className="content">
                <div className="card">
                    <div className="card-header">
                    </div>
                    <div className="card-body">



                        {/* <div className="row">
                            <div className="col-12">
                                <div className="card card-primary card-outline">
                                    <UserCharts
                                        identificador={"grafica1"}
                                        tipo={"bar"}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-6">
                                <div className="card card-outline">
                                <UserCharts
                                identificador={"grafica2"}
                                tipo={"doughnut"}
                                />

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card card-outline">
                                <MembresiaCharts
                                identificador={"grafica3"}
                                tipo={"polarArea"}
                                />


                                </div>
                            </div>
                        </div>










                        {/* <Datatable/> */}
                        {/* <UserCharts /> */}

                    </div>
                    {/* <FooterCard /> */}

                </div>
            </section>
        </div>
    )
}
