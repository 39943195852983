import { fetchConToken } from "../../helpers/fetch";
import Swal from "sweetalert2";

//==============CRUD USUARIOS=================

export const onCrearNameImpuesto = ( impuesto ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken('/api/dashboard/nombreimpuestos', { impuesto:impuesto }, 'POST');
            const body = await resp.json();
       
            if(resp.ok){
                Swal.fire({
                    icon: "success",
                    title: "Se ha creado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.impuestos;
    
     
            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const onEditarNameImpuesto = ( id,impuesto,estado ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken('/api/dashboard/nombreimpuestos', { id,impuesto,estado }, 'PUT');
            const body = await resp.json();
       
            if(resp.ok){
                Swal.fire({
                    icon: "success",
                    title: "Se ha editado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.impuestos;
    
     
            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

export const serviceEliminarNameImpuesto = ( id ) => {
    return async( dispatch ) => {
        try {
            ;

            const resp = await fetchConToken('/api/dashboard/nombreimpuestos', { id }, 'DELETE');
            const body = await resp.json();
            ;
       
            if(resp.ok){
                
                Swal.fire({
                    icon: "success",
                    title: "Se ha inhabilitado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                return body.impuestos;
     
            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

//==============CRUD FIN USUARIOS==============
