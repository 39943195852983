
const updateStateForId = ( arrayState, newElement) => {

    let newArray = arrayState
;
    let indice = arrayState.findIndex(element => element.id === newElement.id);
    console.log(newElement);
    ;
    newArray[indice] = newElement;
    return newArray;
   
}


export{
    updateStateForId,
}