import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";

//==============CRUD IMPUESTOS=================
export const onEditarImpuesto = (id, id_membresia, fecha_vencimiento) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('/api/dashboard/membresia', { id, id_membresia, fecha_vencimiento }, 'PUT');
            const body = await resp.json();

            if (resp.ok) {
                await Swal.fire({
                    icon: "success",
                    title: "Se ha editado la membresia correctamente",
                    showConfirmButton: false,
                    timer: 2000
                });
                window.location.reload();
                return body.membresia;


            } else {
                Swal.fire('Error', body.errors[0].msg, 'error');
            }
        } catch (error) {
            console.log(error);
            console.log('object');
        }
    }
}

//==============CRUD IMPUESTOS==============


export const getImpuestos = async () => {

    const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/impuestos`;
    const resp = await fetch(url,
        {
            method: "GET",
            headers: {
                'Content-type': 'application/json',
                'x-token': localStorage.getItem('token')
            },
        }
    );
    const respuesta = await resp.json();

    if (resp.ok) {
        //setImpuestosNombre(respuesta.impuestos);
        //setCargaImpuestos(true);

        return respuesta.impuestos;

    } else {
        if (respuesta.msg === undefined) {
            //Swal.fire('Error', respuesta.errors[0].msg, 'error');
            throw new Error(respuesta.errors[0].msg);
        } else {
            //Swal.fire('Error', respuesta.msg, 'error');
            throw new Error(respuesta.msg);
        }
    }
}


export const getNits = async () => {

    const url = `${process.env.REACT_APP_MI_VARIABLE_DE_ENTORNO}/api/datos/nits`;
    const resp = await fetch(url,
        {
            method: "GET",
            headers: {
                'Content-type': 'application/json',
                'x-token': localStorage.getItem('token')
            },
        }
    );
    const respuesta = await resp.json();

    if (resp.ok) {

        return respuesta.nits;

    } else {
        if (respuesta.msg === undefined) {
            //Swal.fire('Error', respuesta.errors[0].msg, 'error');
            throw new Error(respuesta.errors[0].msg);
        } else {
            //Swal.fire('Error', respuesta.msg, 'error');
            throw new Error(respuesta.msg);
        }
    }
}


export const editarImpuestosService = async (data) => {


    const resp = await fetchConToken('/api/dashboard/detalleimpuesto/update-impuesto', data, 'POST');
    const body = await resp.json();


    if (resp.ok) {
        return body.msg;

    } else {
        // Swal.fire('Error', body.errors[0].msg, 'error');
        if (body.msg === undefined) {
            //Swal.fire('Error', respuesta.errors[0].msg, 'error');
            throw new Error(body.errors[0].msg);
        } else {
            //Swal.fire('Error', respuesta.msg, 'error');
            throw new Error(body.msg);
        }
    }


}