import Select from "react-select";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../../../helpers/modal_styles";
import "./MembresiaEditModal.css";
import { useDispatch } from "react-redux";
import Moment from "moment";
import { onEditarImpuesto } from "../../../../services/impuestos/impuestos";

const tipoMembresias = [
  { value: "1", label: "Premium" },
  { value: "2", label: "General" },
  { value: "3", label: "Gratis" },
];

export const MembresiaEditModal = ({
  setmodalEditar,
  modalEditar,
  editar,
  state,
  setState,
}) => {
  const dispatch = useDispatch();
  Modal.setAppElement("#root");


  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    ;
  console.log("EDITAR ENTIDAD EDITMODAL",editar);

    const initialState = {
      id: editar.id,
      email: editar.email,
      fechaVencimiento: editar.fechaVencimiento,
      idMembresia: editar.idMembresia,
      membresia: editar.membresia,
    };
    ;
    setFormValues(initialState);
    ;
  }, [editar]);

  //FUNCTION========================
  const closeModal = () => {
    setmodalEditar(false);
  };

  const editarModel = async (e) => {
    e.preventDefault();
    ;
    await dispatch(
      onEditarImpuesto(
        formValues.id,
        formValues.idMembresia,
        Moment(formValues.fechaVencimiento).locale("es_CO").format("YYYY-MM-DD h:mm:ss"),
      )
    );

    // const newArray = updateStateForId(state, resp);
    // setState([...newArray]);
    closeModal();
  };

  //FIN FUNCTION========================

  return (
    <Modal
      isOpen={modalEditar}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="popup">
        <center>
          <h3>Editar Membresia</h3>
        </center>
        <br />
        <form onSubmit={editarModel}>
          <div className="form-row">
            <div className="form-grupin col-md-12">
              <h6>Fecha Vencimiento</h6>
              <input
                placeholder=""
                type="date"
                name="membresia"
                className="form-control"
                value={Moment(formValues.fechaVencimiento).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    fechaVencimiento: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-row">
            <div className=" form-grupin col-md-12">
              <Select
                options={tipoMembresias}
                value={{
                  value: formValues.idMembresia=== 1 ? 1 :formValues.idMembresia === 2 ?  2:3,
                  label:formValues.idMembresia === 1 ? "Premium" :formValues.idMembresia === 2 ?  "General":"Gratis"
                }}
                placeholder=""
                onChange={(e) =>
                  setFormValues({ ...formValues, idMembresia: e.value })
                }
              />
            </div>
          </div>

          <button className="btn btncrear">Editar</button>
        </form>
      </div>
    </Modal>
  );
};
