import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { customStyles } from '../../../../helpers/modal_styles';
import './DetalleImpuestoCrearModal.css'
import { useDispatch } from "react-redux";
import { onCrearDetalleImpuesto } from '../../../../services/detalleimpuestos/detalleImpuestoService';
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Moment from "moment";
import "moment/locale/es";


let ciudad = [];
let impuestoList = [];
let nitList = [];



export const DetalleImpuestoCrearModal = ({ state, setState, setmodalCrear, modalCrear, ciudades, nit, impuestosNombre }) => {
    const dispatch = useDispatch();
    Modal.setAppElement('#root');

    const [formValues, setFormValues] = useState({});


    const [natural, setNatural] = useState(false);
    const [juridico, setJuridico] = useState(false);
    const [contribuyente, setContribuyente] = useState(false);

    useEffect(() => {
        const initialState = {
            ciudad: "",
            nit: "",
            impuesto: "",
            enero: null,
            febrero: null,
            marzo: null,
            abril: null,
            mayo: null,
            junio: null,
            julio: null,
            agosto: null,
            septiembre: null,
            octubre: null,
            noviembre: null,
            diciembre: null
        }
        setFormValues(initialState);
    }, [])

    useEffect(() => {
        ciudad = [];
        ciudades.forEach(element => {
            ciudad.push({ "value": element.id, "label": element.ciudad })
        });
    }, [ciudades])

    useEffect(() => {
        nitList = [];
        nit.forEach(element => {
            nitList.push({ "value": element.id, "label": element.nit })
        });
    }, [nit])

    useEffect(() => {
        impuestoList = [];
        impuestosNombre.forEach(element => {
            impuestoList.push({ "value": element.id, "label": element.impuesto })
        });
    }, [impuestosNombre])


    //FUNCTION========================
    const closeModal = () => {
        setmodalCrear(false);
    }

    const crear = async (e) => {
        e.preventDefault();
        ;
        
            let eneroF = Moment(formValues.enero).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let febreroF = Moment(formValues.febrero).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let marzoF = Moment(formValues.marzo).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let abrilF = Moment(formValues.abril).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let mayoF = Moment(formValues.mayo).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let junioF = Moment(formValues.junio).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let julioF = Moment(formValues.julio).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let agostoF = Moment(formValues.agosto).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let septiembreF = Moment(formValues.septiembre).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let octubreF = Moment(formValues.octubre).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let noviembreF = Moment(formValues.noviembre).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
            let diciembreF = Moment(formValues.diciembre).locale("es_CO").format("YYYY-MM-DD h:mm:ss");
    
        const modeloSend = {
            ciudad: formValues.ciudad,
            nit: formValues.nit,
            impuesto: formValues.impuesto,
            enero: eneroF !== "Fecha inválida"?eneroF:null,
            febrero: febreroF!== "Fecha inválida"?febreroF:null,
            marzo: marzoF!== "Fecha inválida"?marzoF:null,
            abril: abrilF!== "Fecha inválida"?abrilF:null,
            mayo: mayoF!== "Fecha inválida"?mayoF:null,
            junio: junioF!== "Fecha inválida"?junioF:null,
            julio: julioF!== "Fecha inválida"?julioF:null,
            agosto: agostoF!== "Fecha inválida"?agostoF:null,
            septiembre: septiembreF!== "Fecha inválida"?septiembreF:null,
            octubre: octubreF!== "Fecha inválida"?octubreF:null,
            noviembre: noviembreF!== "Fecha inválida"?noviembreF:null,
            diciembre: diciembreF!== "Fecha inválida"?diciembreF:null,
            natural: natural,
            juridico: juridico,
            contribuyente: contribuyente
        }
        ;

        console.log("MODELO SEND:::::", modeloSend);

        const resp = await dispatch(onCrearDetalleImpuesto(
            modeloSend.ciudad,
            modeloSend.nit,
            modeloSend.impuesto,
            modeloSend.enero,
            modeloSend.febrero,
            modeloSend.marzo,
            modeloSend.abril,
            modeloSend.mayo,
            modeloSend.junio,
            modeloSend.julio,
            modeloSend.agosto,
            modeloSend.septiembre,
            modeloSend.octubre,
            modeloSend.noviembre,
            modeloSend.diciembre,
            modeloSend.natural,
            modeloSend.juridico,
            modeloSend.contribuyente,

        ));
        if (!!resp) {
            setState([...state, resp]);
            function mensaje() {

                window.location.reload()

                }
                setTimeout(mensaje,1900);
        }

        closeModal();

    }

    const onNatural = async (e) => {
        e.preventDefault();
        setNatural(!natural);

    }
    const onJuridico = async (e) => {
        e.preventDefault();
        setJuridico(!juridico);
    }
    const onContribuyente = async (e) => {
        e.preventDefault();
        setContribuyente(!contribuyente);
    }

    //FIN FUNCTION========================
    console.log(formValues);

    return (
        <Modal
            isOpen={modalCrear}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <center><h3>Crear Impuesto</h3></center>
            <br />
            <form onSubmit={crear}>

                <div className="form-row">

                    <div className=" form-grupin col-md-12">

                        <Select
                            defaultValue={[]}
                            isMulti={true}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={ciudad}
                            placeholder="Ciudad"
                            onChange={(e) => {
                                setFormValues({ ...formValues, ciudad: e })
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className=" form-grupin col-md-12">
                        <Select
                            defaultValue={[]}
                            isMulti={true}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            options={nitList}
                            placeholder="Nit"
                            onChange={(e) => {


                                setFormValues({ ...formValues, nit: e })
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className=" form-grupin col-md-12">
                        <Select
                            options={impuestoList}
                            placeholder="Impuesto"
                            onChange={(e) => {


                                setFormValues({ ...formValues, impuesto: Number(e.value), labelImpuesto: e.label })
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-grupin col-md-4">
                        <h6>Enero</h6>
                        <input
                            placeholder="Enero"
                            type="date"
                            name="enero"
                            className="form-control"
                            value={formValues.enero}
                            onChange={(e) =>
                                setFormValues({ ...formValues, enero: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Febrero</h6>
                        <input
                            placeholder="Febrero"
                            type="date"
                            name="febrero"
                            className="form-control"
                            value={formValues.febrero}
                            onChange={(e) =>
                                setFormValues({ ...formValues, febrero: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Marzo</h6>
                        <input
                            placeholder="Marzo"
                            type="date"
                            name="marzo"
                            className="form-control"
                            value={formValues.marzo}
                            onChange={(e) =>
                                setFormValues({ ...formValues, marzo: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-grupin col-md-4">
                        <h6>Abril</h6>
                        <input
                            placeholder="Abril"
                            type="date"
                            name="abril"
                            className="form-control"
                            value={formValues.abril}
                            onChange={(e) =>
                                setFormValues({ ...formValues, abril: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Mayo</h6>
                        <input
                            placeholder="Mayo"
                            type="date"
                            name="mayo"
                            className="form-control"
                            value={formValues.mayo}
                            onChange={(e) =>
                                setFormValues({ ...formValues, mayo: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Junio</h6>
                        <input
                            placeholder="Junio"
                            type="date"
                            name="junio"
                            className="form-control"
                            value={formValues.junio}
                            onChange={(e) =>
                                setFormValues({ ...formValues, junio: e.target.value })
                            }
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-grupin col-md-4">
                        <h6>Julio</h6>
                        <input
                            placeholder="Julio"
                            type="date"
                            name="julio"
                            className="form-control"
                            value={formValues.julio}
                            onChange={(e) =>
                                setFormValues({ ...formValues, julio: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Agosto</h6>
                        <input
                            placeholder="Agosto"
                            type="date"
                            name="agosto"
                            className="form-control"
                            value={formValues.agosto}
                            onChange={(e) =>
                                setFormValues({ ...formValues, agosto: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Septiembre</h6>
                        <input
                            placeholder="Septiembre"
                            type="date"
                            name="septiembre"
                            className="form-control"
                            value={formValues.septiembre}
                            onChange={(e) =>
                                setFormValues({ ...formValues, septiembre: e.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-grupin col-md-4">
                        <h6>Octubre</h6>
                        <input
                            placeholder="Octubre"
                            type="date"
                            name="octubre"
                            className="form-control"
                            value={formValues.octubre}
                            onChange={(e) =>
                                setFormValues({ ...formValues, octubre: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Noviembre</h6>
                        <input
                            placeholder="Noviembre"
                            type="date"
                            name="noviembre"
                            className="form-control"
                            value={formValues.noviembre}
                            onChange={(e) =>
                                setFormValues({ ...formValues, noviembre: e.target.value })
                            }
                        />
                    </div>
                    <div className="form-grupin col-md-4">
                        <h6>Diciembre</h6>
                        <input
                            placeholder="Diciembre"
                            type="date"
                            name="diciembre"
                            className="form-control"
                            value={formValues.diciembre}
                            onChange={(e) =>
                                setFormValues({ ...formValues, diciembre: e.target.value })
                            }
                        />
                    </div>
                </div>



                <div className="form-row">
                    <div className=" form-grupin col-md-4">

                        <div className='checked-person'>
                            <div className={natural ? 'container-checked' : 'container-close'}>
                                {
                                    (natural ?
                                        <AiFillCheckCircle
                                            size={40}
                                            color='fff'
                                            onClick={onNatural}

                                        />
                                        :
                                        <AiFillCloseCircle
                                            size={40}
                                            color='fff'
                                            onClick={onNatural}

                                        />)
                                }
                            </div>

                            <h4>Natural</h4>
                        </div>
                    </div>

                    <div className=" form-grupin col-md-4">
                        <div className='checked-person'>
                            <div className={juridico ? 'container-checked' : 'container-close'}>
                                {
                                    (juridico ?
                                        <AiFillCheckCircle
                                            size={40}
                                            color='fff'
                                            onClick={onJuridico}
                                        />
                                        :
                                        <AiFillCloseCircle
                                            size={40}
                                            color='fff'
                                            onClick={onJuridico}
                                        />)
                                }

                            </div>

                            <h4>Jurídico</h4>
                        </div>
                    </div>
                    <div className=" form-grupin col-md-4">
                        <div className='checked-person'>
                            <div className={contribuyente ? 'container-checked' : 'container-close'}>
                                {
                                    (contribuyente ?
                                        <AiFillCheckCircle
                                            size={40}
                                            color='fff'
                                            onClick={onContribuyente}
                                        />
                                        :
                                        <AiFillCloseCircle
                                            size={40}
                                            color='fff'
                                            onClick={onContribuyente}

                                        />)
                                }
                            </div>

                            <h4>Contribuyente</h4>
                        </div>
                    </div>
                </div>

                <button className="btn btncrear">Crear</button>
            </form>

        </Modal>
    )
}
