import { fetchConToken, fetchSinToken } from "../../helpers/fetch";
import { types } from "../../types/types";
import Swal from "sweetalert2";

export const startLogin = (email, password) => {
  return async (dispatch) => {
    const resp = await fetchSinToken("auth/login", { email, password }, "POST");
    const body = await resp.json();

    if (resp.ok) {
      localStorage.setItem("token", body.token);

      dispatch(
        login({
          uid: body.oUsuario.id,
          name: body.oUsuario.email,
        })
      );
    } else {
      Swal.fire("Error", body.errors[0].msg, "error");
    }
  };
};


export const renewToken = () => {
  return async (dispatch) => {

    const resp = await fetchConToken("auth/renew");
    const body = await resp.json();
    if (resp.ok) {
      console.log(body);
      localStorage.setItem("token", body.token);
      dispatch({
        type: types.authLogin,
        payload: {
          uid: body.oUsuario.id,
          name: body.oUsuario.email,
        },
      });
    } else {
    }
  };
};

export const startLogout = () => {
  return async (dispatch) => {
    localStorage.removeItem("token");

    dispatch({
      type: types.logout,
      payload: {
        uid: null,
        name: null
      }
    });
  };
};

const login = (user) => ({
  type: types.authLogin,
  payload: user,
});
